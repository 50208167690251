<template>
  <v-card tile class="d-flex flex-row justify-center align-center">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center">
      <v-container class="pa-md-12 pa-0">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>Zelle</h2>
            <v-row
              dense
              class="d-flex flex-row justify-center align-center mt-1"
            >
              <v-col
                cols="3"
                class="ma-0 pa-0 d-flex flex-row justify-center align-center"
              >
                <div
                  class="d-flex flex-column justify-center align-center payment-method"
                >
                  <div
                    class="payment-method-avatar d-flex flex-row justify-center align-center elevation-6 background-dark-blue"
                  >
                    <v-img
                      src="../assets/payment-methods/zelle.svg"
                      :max-width="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                      :max-height="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                      contain
                    >
                    </v-img>
                  </div>
                  <div
                    class="d-flex flex-row justify-center payment-method-name font-weight-bold mt-1"
                    style="width: 100%"
                  >
                    Zelle
                  </div>
                  <div
                    class="d-flex flex-column flex-md-row justify-center payment-method-currency align-center"
                    style="width: 100px"
                  >
                    <span class="font-weight-bold"> Usd </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <p>
              Make your corporate balance top-ups with our automated system
              zelle
            </p>
          </v-col>
        </v-row>
        <template>
          <v-stepper v-model="activeStep" class="custom-header" elevation="0">
            <!-- Header -->
            <v-stepper-header>
              <!-- Header: Step 1 -->
              <v-stepper-step
                :complete="activeStep > 1"
                step="1"
                :color="activeStep > 1 ? '#3BD4AE' : '#EF4370'"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >01</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 1">
                    <span class="text--primary text-sm font-weight-semibold"
                      >Amount to recharge</span
                    >
                    <span class="text--secondary text-xs"
                      >Define the quantity to recharge</span
                    >
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 2 -->
              <v-stepper-step
                :complete="activeStep > 2"
                step="2"
                :color="activeStep > 2 ? '#3BD4AE' : '#EF4370'"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >02</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 2">
                    <span class="text--primary text-sm font-weight-semibold"
                      >Zelle to make</span
                    >
                    <span class="text--secondary text-xs"
                      >Amount and coordinates to make the zelle</span
                    >
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 3 -->
              <v-stepper-step
                step="3"
                :color="
                  activeStep === 3 && zelleSuccess ? '#3BD4AE' : '#EF4370'
                "
                :complete="activeStep === 3 && zelleSuccess"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3"
                    >03</span
                  >
                  <div class="d-flex flex-column" v-if="activeStep === 3">
                    <span class="text--primary text-sm font-weight-semibold"
                      >Zelle validation</span
                    >
                    <span class="text--secondary text-xs" v-if="!zelleSuccess"
                      >Wait while we validate your zelle</span
                    >
                    <span class="text--secondary text-xs" v-if="zelleSuccess"
                      >Zelle validated</span
                    >
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper-header>

            <!-- Stepper Items -->
            <v-stepper-items>
              <!-- Stepper Content: Step 1 -->
              <v-stepper-content step="1">
                <v-card class="pt-8" elevation="0">
                  <v-row dense justify="center">
                    <v-col md="3" cols="12">
                      <v-text-field
                        label="Amount to add"
                        outlined
                        dense
                        v-model.lazy="price"
                        v-money="money"
                        prefix="$"
                        @keydown="nameKeydown($event)"
                        max-width="200"
                      />
                    </v-col>
                  </v-row>
                </v-card>

                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    @click="activeStep = 2"
                    :disabled="price <= 0"
                  >
                    Next
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Stepper Content: Step 2 -->
              <v-stepper-content step="2">
                <ValidationObserver v-slot="{ invalid }">
                  <v-card class="pt-8 pb-8" elevation="0" v-show="!loading">
                    <!-- Datos bancarios -->
                    <v-row dense>
                      <v-col
                        cols="12"
                        md="4"
                        style="border-right: 1px solid #ef4370"
                        class="pr-md-6"
                      >
                        <v-row dense>
                          <v-col cols="12" class="d-flex justify-center">
                            <h3>Zelle coordinates to transfer</h3>
                          </v-col>
                          <v-col cols="12">
                            Amount:
                            <span class="font-weight-bold">
                              {{ computedDollarZelle | currency }}
                            </span>
                            <span class="ml-2">
                              <v-tooltip top v-model="showTooltipAmount">
                                <template v-slot:activator="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    icon
                                    @click="handleCopyClipboard('amountNumber')"
                                    small
                                  >
                                    <v-icon small>{{
                                      icons.mdiContentCopy
                                    }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>Amount copied!</span>
                              </v-tooltip>
                            </span>
                          </v-col>
                          <v-col cols="12">
                            Email:
                            <span class="font-weight-bold">
                              {{ bank.email }}
                            </span>
                            <span class="ml-2">
                              <v-tooltip top v-model="showTooltipEmail">
                                <template v-slot:activator="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    icon
                                    @click="handleCopyClipboard('email')"
                                    small
                                  >
                                    <v-icon small>{{
                                      icons.mdiContentCopy
                                    }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>Email copied!</span>
                              </v-tooltip>
                            </span>
                          </v-col>
                          <v-col cols="12">
                            Account holder:
                            <span class="font-weight-bold">
                              {{ bank.accountHolder }}
                            </span>
                            <span class="ml-2">
                              <v-tooltip top v-model="showTooltipAccountHolder">
                                <template v-slot:activator="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    icon
                                    @click="
                                      handleCopyClipboard('accountHolder')
                                    "
                                    small
                                  >
                                    <v-icon small>{{
                                      icons.mdiContentCopy
                                    }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>Account holder copied!</span>
                              </v-tooltip>
                            </span>
                          </v-col>
                          <v-col cols="12">
                            <div
                              :class="
                                $vuetify.breakpoint.mdAndUp && 'round-corners'
                              "
                            >
                              Memo number:
                              <br v-if="!$vuetify.breakpoint.mdAndUp" />
                              <span class="font-weight-bold primary--text">
                                {{ bank.memoNumber }}
                              </span>
                              <span class="ml-2">
                                <v-tooltip top v-model="showTooltipMemoNumber">
                                  <template v-slot:activator="{ attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      icon
                                      @click="handleCopyClipboard('memoNumber')"
                                      small
                                    >
                                      <v-icon small>{{
                                        icons.mdiContentCopy
                                      }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Memo number copied!</span>
                                </v-tooltip>
                              </span>
                              <span class="ml-1">
                                <v-tooltip
                                  top
                                  :max-width="300"
                                  content-class="test"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small>{{
                                      icons.mdiInformationOutline
                                    }}</v-icon>
                                  </template>
                                  <template #default>
                                    <v-card
                                      class="pt-5 pb-5 px-6 fontUrbanist elevation-24"
                                      :min-width="
                                        $vuetify.breakpoint.mdAndUp ? 350 : 300
                                      "
                                      max-width="600"
                                    >
                                      <v-row dense class="mb-2">
                                        <v-col cols="12">
                                          <h1 class="text-decoration-underline">
                                            Memo number
                                          </h1>
                                        </v-col>
                                      </v-row>
                                      <v-row dense class="text-lg text-justify">
                                        <v-col cols="12">
                                          To
                                          <span
                                            class="primary--text font-weight-bold"
                                            >validate automatically</span
                                          >
                                          your zelle payment it is necesssary to
                                          put the following memo number:
                                        </v-col>
                                      </v-row>
                                      <v-row dense>
                                        <v-col
                                          cols="12"
                                          class="text-xl primary--text"
                                        >
                                          {{ bank.memoNumber }}
                                        </v-col>
                                      </v-row>
                                      <br />
                                      <v-divider></v-divider>
                                      <br />
                                      <v-row dense>
                                        <v-col cols="12">
                                          <h2 class="pa-2">Example:</h2>
                                        </v-col>
                                      </v-row>
                                      <v-row dense class="mt-4">
                                        <v-col cols="12">
                                          <v-text-field
                                            label="Account holder"
                                            v-model="bank.accountHolder"
                                            dense
                                            class="pa-2"
                                          >
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row dense style="margin-top: -15px">
                                        <v-col cols="12">
                                          <v-text-field
                                            label="Email"
                                            v-model="bank.email"
                                            dense
                                            class="pa-2"
                                          >
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row dense style="margin-top: -15px">
                                        <v-col cols="12">
                                          <v-text-field
                                            label="Amount to add"
                                            v-model.lazy="price"
                                            v-money="money"
                                            prefix="$"
                                            dense
                                            class="pa-2"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row
                                        style="
                                          border: 3.5px solid #3bd4ae;
                                          border-radius: 25px;
                                          margin-top: -25px;
                                        "
                                        dense
                                      >
                                        <v-col cols="12" class="pt-2 pb-0">
                                          <v-text-field
                                            label="Memo"
                                            v-model="bank.memoNumber"
                                            dense
                                            class="pa-2"
                                          >
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </template>
                                </v-tooltip>
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="8" class="pl-md-8">
                        <v-row dense>
                          <v-col cols="12">
                            <h3>Input the data related to the zelle</h3>
                          </v-col>
                        </v-row>
                        <v-row dense class="">
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              name="payment date"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <DatePickerInput
                                label="Payment date"
                                placeholder="Place your payment date"
                                v-model="zelleForm.date"
                                outlined
                                dense
                                :max="maxDay"
                                :tooltip="{
                                  information:
                                    'Pick the date when you made the zelle payment',
                                }"
                                :error-messages="errors[0] && errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" class="pl-md-4">
                            <ValidationProvider
                              name="memo number"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                label="Memo number"
                                v-model="zelleForm.memo"
                                outlined
                                dense
                                :error-messages="errors[0] && errors[0]"
                              >
                                <!-- <template v-slot:append-outer>
                                  <v-tooltip bottom max-width="150">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </template>
                                    <span
                                      >Coloca el número de memo del zelle</span
                                    >
                                  </v-tooltip>
                                </template> -->
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              name="zelle propietary"
                              rules="required|min:2|max:100"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                label="Zelle propietary"
                                v-model="zelleForm.propietary"
                                outlined
                                dense
                                :error-messages="errors[0] && errors[0]"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip bottom max-width="150">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </template>
                                    <span
                                      >The zelle propieatry is the person or
                                      entity that made the payment</span
                                    >
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense class="d-flex flex-row align-center">
                          <v-col
                            cols="12"
                            class="d-flex flex-row align-center justify-start"
                          >
                            <ValidationProvider
                              :name="'Would you like a custom invoice?'"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <!-- v-model="manualTopUpForm.details.customInvoice" -->
                              <v-radio-group
                                v-model="zelleForm.customInvoice"
                                :error-messages="errors[0] && errors[0]"
                                :label="'Would you like a custom invoice?'"
                                dense
                                row
                              >
                                <v-radio :label="'Yes'" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                              </v-radio-group>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row dense class="d-flex align-baseline">
                          <v-col
                            cols="12"
                            md="6"
                            class="d-flex align-center ma-0 pa-0"
                          >
                            <ValidationProvider
                              :name="'This top up corresponds to a proforma?'"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <!-- v-model="manualTopUpForm.details.customInvoice" -->
                              <v-radio-group
                                :error-messages="errors[0] && errors[0]"
                                :label="'This top up corresponds to a proforma?'"
                                dense
                                row
                                v-model="zelleForm.proforma"
                              >
                                <v-radio :label="'Yes'" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                              </v-radio-group>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="5"
                            class="d-flex align-center ma-0 pa-0"
                            v-if="zelleForm.proforma"
                          >
                            <ValidationProvider
                              v-if="zelleForm.proforma"
                              :name="'Proforma'"
                              rules="required"
                              v-slot="{ errors }"
                              slim
                            >
                              <v-text-field
                                v-model="zelleForm.proformaNumber"
                                label="Proforma"
                                outlined
                                dense
                                :error-messages="errors[0] && errors[0]"
                              >
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <!-- <br />
                    <v-divider></v-divider>
                    <br /> -->
                    <!-- datos -->
                  </v-card>
                  <v-card v-show="loading" class="pt-8 pb-8" elevation="0">
                    <v-progress-circular
                      class="mt-10 mb-10"
                      indeterminate
                      :size="64"
                      color="primary"
                    />
                    <p class="text-xl">Fetching data...</p>
                  </v-card>

                  <div class="d-flex justify-center" v-if="!loading">
                    <v-btn
                      outlined
                      color="primary"
                      @click="activeStep = 1"
                      :disabled="loading"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      @click="activeStep = 3"
                      :disabled="loading || invalid"
                    >
                      Next
                    </v-btn>
                  </div>
                </ValidationObserver>
              </v-stepper-content>

              <!-- Stepper Content: Step 3 -->
              <v-stepper-content step="3">
                <v-card class="pt-8 pb-8" elevation="0" v-show="!loading">
                  <template v-if="!zelleSuccess">
                    <v-row dense>
                      <v-col cols="12" class="d-flex flex-column align-center">
                        <v-img
                          src="@/views/Balance/assets/notFound.png"
                          width="250"
                          class="wobble-hor-bottom"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <br />
                        <h2>We are sorry!</h2>
                        <template v-if="errorCode === '705'">
                          <h3 class="fontUrbanist">
                            Zelle
                            <span class="font-weight-bold">not found</span>
                            in Ridery's database
                          </h3>
                        </template>
                        <template v-if="errorCode === '704'">
                          <h3 class="fontUrbanist">
                            The zelle was
                            <span class="font-weight-bold">used</span>
                          </h3>
                        </template>
                        <span>
                          You can
                          <span class="font-weight-bold">review</span> the zelle
                          data and
                          <span class="font-weight-bold">retry</span> or request
                          asistance
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="zelleSuccess">
                    <v-row dense>
                      <v-col cols="12" class="d-flex flex-column align-center">
                        <v-img
                          src="@/views/Balance/assets/found.png"
                          width="250"
                          class="pulsate-fwd"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <br />
                        <h2>Success!</h2>
                        <h3 class="fontUrbanist">
                          Zelle
                          <span class="font-weight-bold">found</span> in
                          Ridery's database
                        </h3>
                        <span>
                          The <span class="font-weight-bold">following </span>
                          <span class="font-weight-bold color-green-text">{{
                            zelleDetails.wallet_amount | currency
                          }}</span>
                          has been added to your
                          <span class="font-weight-bold"
                            >corporate balance</span
                          >
                          <br />
                          Corporate balance:
                          <span class="font-weight-bold">{{
                            zelleDetails.total_wallet_amount | currency
                          }}</span>
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                </v-card>
                <v-card v-show="loading" class="pt-8 pb-8" elevation="0">
                  <v-progress-circular
                    class="mt-10 mb-10"
                    indeterminate
                    :size="64"
                    color="primary"
                  />
                  <p class="text-xl">Validating zelle data...</p>
                </v-card>

                <div class="d-flex justify-center" v-if="!loading">
                  <v-btn
                    v-if="!zelleSuccess"
                    outlined
                    @click="activeStep = 2"
                    color="primary"
                  >
                    Review zelle data
                  </v-btn>
                  <v-btn
                    v-if="zelleSuccess"
                    color="primary"
                    class="ml-2"
                    @click="handleClose(true)"
                  >
                    Complete
                  </v-btn>
                </div>
                <div
                  class="d-flex justify-center mt-4"
                  v-if="activeStep === 3 && !zelleSuccess && !loading"
                >
                  <a
                    class="text-decoration-underline text-lg"
                    href="https://api.whatsapp.com/send?phone=584127156879&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC"
                    target="_blank"
                  >
                    Request assistance
                  </a>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
        <v-row
          class="d-flex flex-md-row flex-column-reverse justify-center align-center"
          v-if="activeStep !== 3"
        >
          <v-col cols="12" md="4" v-if="!loading">
            <a
              text
              block
              color="primary"
              class="text-decoration-underline text-lg"
              :class="!$vuetify.theme.isDark && 'color-blue-text'"
              @click="handleClose()"
            >
              Cancel
            </a>
          </v-col>
          <!-- <v-col cols="12" md="4">
            <v-btn color="primary" block @click="handleClose()">
              Aceptar
            </v-btn>
          </v-col> -->
        </v-row>
      </v-container>
      <v-container></v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import {
  mdiMagnify,
  mdiAlert,
  mdiContentCopy,
  mdiInformationOutline,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import { VMoney } from "v-money";
import bankList from "@/api/bankList";
import PhoneInput from "@/components/PhoneInput";
import DatePickerInput from "@/components/DatePickerInput";
import { mask } from "vue-the-mask";

export default {
  props: {},
  components: {
    PhoneInput,
    DatePickerInput,
  },
  directives: { mask },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
        mdiContentCopy,
        mdiInformationOutline,
      },
      loading: false,
      price: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      activeStep: 1,
      bank: {
        email: "pagosriderycorp@xpsolutions.co",
        accountHolder: "Express Payment Solutions LLC",
        memoNumber: "RIDE-XXXXXX",
      },
      showTooltipAmount: false,
      showTooltipRif: false,
      showTooltipEmail: false,
      showTooltipAccountHolder: false,
      showTooltipMemoNumber: false,
      currencyRate: 1,
      bankList: bankList,
      zelleForm: {
        date: "",
        memo: "",
        propietary: "",
        customInvoice: undefined,
        proforma: undefined,
        proformaNumber: "",
      },
      zelleSuccess: false,
      errorCode: null,
      zelleDetails: {
        wallet_amount: "",
        total_wallet_amount: "",
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this._timerId);
  },
  methods: {
    ...mapActions(["setLoading"]),

    handleClose(success) {
      if (success) {
        this.$emit("submit", true);
      }
      this.$emit("submit", null);
    },

    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    handleCopyClipboard(reference) {
      switch (reference) {
        case "bankNumber":
          navigator.clipboard.writeText(this.bank.number);
          this.showTooltipEmail = true;
          this._timerId = setTimeout(
            () => (this.showTooltipEmail = false),
            800
          );
          break;
        case "rifNumber":
          navigator.clipboard.writeText(this.bank.rif);
          this.showTooltipRif = true;
          this._timerId = setTimeout(() => (this.showTooltipRif = false), 800);
          break;
        case "amountNumber":
          navigator.clipboard.writeText(this.computedDollarZelle);
          this.showTooltipAmount = true;
          this._timerId = setTimeout(
            () => (this.showTooltipAmount = false),
            800
          );
          break;
        case "email":
          navigator.clipboard.writeText(this.bank.email);
          this.showTooltipEmail = true;
          this._timerId = setTimeout(
            () => (this.showTooltipEmail = false),
            800
          );
          break;
        case "accountHolder":
          navigator.clipboard.writeText(this.bank.accountHolder);
          this.showTooltipAccountHolder = true;
          this._timerId = setTimeout(
            () => (this.showTooltipAccountHolder = false),
            800
          );
          break;
        case "memoNumber":
          navigator.clipboard.writeText(this.bank.memoNumber);
          this.showTooltipMemoNumber = true;
          this._timerId = setTimeout(
            () => (this.showTooltipMemoNumber = false),
            800
          );
          break;
        default:
          break;
      }
    },
    handleFilterAutocompleteBanks(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const name = _item.name.toLowerCase();
      const code = _item.code.toLowerCase();

      return name.indexOf(searchText) > -1 || code.indexOf(searchText) > -1;
    },
    handleZellememo() {
      this.zelleForm.memo = this.zelleForm.memo.toUpperCase();
    },
    async getZelleDetails() {
      this.setLoading(true);
      try {
        const getZelleDetailsResponse = await axios.get("/me");
        if (!getZelleDetailsResponse?.data?.zelle_corporate_email) {
          throw new Error("No zelle definition");
        }
        const { zelle_corporate_email } = getZelleDetailsResponse.data;
        this.bank.email = zelle_corporate_email;
      } catch (error) {
        console.error(error);
        this.$dialog.notify.error(error.message);
        this.$emit("submit", null);
      } finally {
        this.setLoading(false);
      }
    },
  },
  computed: {
    ...mapState(["stateDriverCancelledEvent"]),
    ...mapState("auth", ["unique_id"]),

    computedPrice() {
      if (this.price.length >= 8) {
        return parseFloat(this.price.replace(/,/g, ""));
      } else {
        return parseFloat(this.price);
      }
    },
    computedDollarZelle() {
      return parseFloat(this.computedPrice).toFixed(2);
    },
    maxDay() {
      let today = new Date();
      return this.$moment(today).add(-4, "hours").toISOString();
    },
  },
  watch: {
    activeStep: async function (newVal) {
      if (newVal === 2) {
        // this.loading = true;
        // try {
        //   const { data } = await axios.get("/get_currency_rate");
        //   if (data.success) {
        //     this.currencyRate = data.currency_rate;
        //   } else {
        //     throw new Error("Error getting Currency Rate");
        //   }
        // } catch (error) {
        //   this.$dialog.notify.error(error.message);
        // }
        // this.loading = false;
      }
      if (newVal === 3) {
        this.loading = true;
        try {
          const {
            propietary,
            date,
            memo,
            customInvoice,
            proforma,
            proformaNumber,
          } = this.zelleForm;
          let params = {
            date: date,
            amount: this.computedPrice,
            memo: memo,
            owner_input: propietary,
            custom_invoice: customInvoice,
            proforma: proforma ? proformaNumber : "",
          };
          const { data } = await axios.post(
            "/corporate_add_wallet_with_zelle",
            params
          );
          if (data.success) {
            console.log(data);
            this.zelleSuccess = true;
            const { wallet_amount, total_wallet_amount } = data;
            this.zelleDetails["wallet_amount"] = wallet_amount;
            this.zelleDetails["total_wallet_amount"] = total_wallet_amount;
          } else {
            console.log(data);

            if (data.error_code === "705") {
              await new Promise((r) => setTimeout(r, 31000));
              let dataRetry = await axios.post(
                "/corporate_add_wallet_with_pago_movil",
                params
              );
              if (dataRetry.data.success) {
                this.zelleSuccess = true;
                const { wallet_amount, total_wallet_amount } = data;
                this.zelleDetails["wallet_amount"] = wallet_amount;
                this.zelleDetails["total_wallet_amount"] = total_wallet_amount;
              } else {
                this.zelleSuccess = false;
                this.errorCode = data.error_code;
              }
            }
            this.zelleSuccess = false;
            this.errorCode = data.error_code;
          }
        } catch (error) {
          this.$dialog.notify.error(error.message);
        }
        this.loading = false;
      }
    },
  },
  mounted() {
    let baseMemo = `${this.unique_id}`;
    while (baseMemo.length < 6) {
      baseMemo = "0" + baseMemo;
    }
    this.bank.memoNumber = "RIDE-" + baseMemo;
    this.zelleForm.memo = this.bank.memoNumber;
  },
  async created() {
    await this.getZelleDetails();
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)
    // color: white
    // opacity: 1
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    min-height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px

.round-corners
  border-radius: 25px
  border: 2px solid #000522

.test
  &.v-tooltip__content
    background: transparent
    opacity: 1 !important
</style>

<style lang="scss" scoped>
.payment-method {
  width: 100px;
  height: 100px;
  &-avatar {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  &-currency {
    font-size: 10px;
  }
  &-name {
    font-size: 10px;
    text-align: center;
  }
}
</style>
