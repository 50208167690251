<template>
  <v-card tile class="dialog-container">
    <v-container
      fluid
      class="d-flex flex-column align-center ma-0 pa-0"
      :class="{
        'justify-space-between': !loading && !catastrophicError,
        'justify-center': loading || catastrophicError,
      }"
      style="height: 100%; min-height: 200px"
    >
      <div style="width: 100%" class="px-4">
        <v-row class="ma-0 d-flex" dense>
          <div class="d-flex justify-space- w-full">
            <v-col cols="9">
              <div class="d-flex">
                <h2>Historial de créditos</h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-md-6 ml-2 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>{{ tooltipCredit }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="3" class="d-flex flex-row justify-end">
              <v-btn @click="handleClose" icon :disabled="loadingTable">
                <v-icon color="primary" large>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-col>
          </div>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn
              outlined
              color="primary"
              @click="handleExport()"
              :disabled="loadingTable"
            >
              <v-icon> {{ icons.mdiDownload }}</v-icon>
              <span class="ml-2">{{ ExportMessage }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="ma-0 mt-2">
          <v-col cols="12" class="px-1">
            <v-row dense>
              <v-col cols="12" class="pa-0">
                <v-data-table
                  :loading="loadingTable"
                  :headers="table.headers"
                  :disable-sort="true"
                  :items="table.items"
                  class="dataTable elevation-1"
                  calculate-widths
                  hide-default-footer
                  disable-pagination
                  fixed-header
                  mobile-breakpoint="0"
                  :dense="true"
                >
                  <!-- <template #top>
                    <v-row dense>
                      <v-col
                        cols="12"
                        class="d-flex flex-row align-center justify-end"
                      >
                        <v-tooltip top :max-width="300">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="primary"
                              @click="getCorporateCredits()"
                            >
                              <v-icon>
                                {{ icons.mdiRefresh }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span> {{ reloadMessage }} </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </template> -->

                  <template v-slot:header.amount_added="{ header }">
                    <div class="d-flex justify-center">
                      {{ header.text }}
                      <v-tooltip top :max-width="300">
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            medium
                            v-on="on"
                            class="ml-md-6 ml-2 color-gray-text"
                          >
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                        </template>
                        <span>{{ tooltipAmountAdded }}</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <template #item.amount_added="{ value }">
                    <span v-if="value !== '-'">
                      {{ value | currency }}
                    </span>
                    <span v-else>{{ value }}</span>
                  </template>

                  <template #item.amount_paid="{ value }">
                    <span v-if="value !== '-'">
                      {{ value | currency }}
                    </span>
                    <span v-else>{{ value }}</span>
                  </template>

                  <template #item.status="{ value }">
                    <v-chip
                      :color="getStatusColor(value)"
                      text-color="white"
                      small
                    >
                      {{ getStatusText(value) }}
                    </v-chip>
                  </template>

                  <template #item.actions="{ item }">
                    <v-menu
                      rounded
                      offset-y
                      :disabled="loading"
                      nudge-left="50"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" icon>
                          <v-icon> {{ icons.mdiDotsVertical }} </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item id="btn-action-view-credit-details" link>
                          <p @click="handleViewDetail(item)">Ver detalles</p>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row
              dense
              class="d-flex flex-row justify-space-between align-center"
            >
              <v-col
                cols="12"
                md="4"
                class="d-flex flex-row justify-end align-center pt-8"
              >
                <v-select
                  :label="resultPerPage"
                  :items="[5, 10, 20, 30]"
                  v-model="table.limit"
                  :disabled="table.tableLoading"
                  dense
                  outlined
                  @input="getCorporateCredits()"
                >
                </v-select>
                <v-autocomplete
                  :class="!$vuetify.mdAndUp && 'ml-2'"
                  :label="goToPage"
                  :items="Array.from(Array(table.pages + 1).keys()).slice(1)"
                  v-model="table.currentPage"
                  :disabled="table.tableLoading"
                  dense
                  outlined
                  @input="table.currentPage && getCorporateCredits()"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="
                  d-flex
                  flex-row
                  justify-md-end justify-center
                  align-start
                "
              >
                <v-pagination
                  v-model="table.currentPage"
                  :length="table.pages"
                  total-visible="12"
                  @input="getCorporateCredits()"
                  :disabled="table.tableLoading"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import moment from "moment";
import {
  mdiInformationOutline,
  mdiRefresh,
  mdiClose,
  mdiDownload,
  mdiDotsVertical,
} from "@mdi/js";
import BaseDialog from "@/components/BaseDialog.vue";
import axios from "@/plugins/axios";
import ExportSocketDialog from "../../../components/ExportSocketDialog.vue";
import generateSocketIdMixin from "@/mixins/generateSocketIdMixin";
import CreditDetailModal from "./CreditDetailModal.vue";

const CORPORATE_CREDIT_STATUS_ENUM = {
  CANCEL: -1,
  OPEN: 1,
  ADDED: 2,
  ABOUT_TO_EXPIRE: 3,
  EXPIRED: 4,
  PAID_OFF: 5,
  INVOICED: 6,
  REVERTED: 7,
};

export default {
  name: "CorporateCreditsModal",
  components: {
    BaseDialog,
    ExportSocketDialog,
  },
  mixins: [generateSocketIdMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    catastrophicError: {
      type: Boolean,
      default: false,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "Sorry, there was an error",
    },
    closeMessage: {
      type: String,
      default: "Close",
    },
    tooltipCredit: {
      type: String,
      default: "Historial de créditos",
    },
    table: {
      type: Object,
      required: true,
    },
    resultPerPage: {
      type: String,
      default: "Resultados por página",
    },
    goToPage: {
      type: String,
      default: "Ir a la página",
    },
    reloadMessage: {
      type: String,
      default: "Recargar tabla",
    },
    ExportMessage: {
      type: String,
      default: "Exportar Excel",
    },
    exportDialogTitle: {
      type: String,
      default: "Exportar Excel",
    },
    processingMessage: {
      type: String,
      default: "Processing page",
    },
    ofMessage: {
      type: String,
      default: "of",
    },
    downloadMessage: {
      type: String,
      default: "Download",
    },
    goBackMessage: {
      type: String,
      default: "GoBack",
    },
    expiredMessage: {
      type: String,
      default: "expired",
    },
    youCanDownloadMessage: {
      type: String,
      default: "You can download the export file",
    },
    exportFileHasExpiredMessage: {
      type: String,
      default: "The export file has",
    },
    processingPageMessage: {
      type: String,
      default: "Processing pages for Excel file",
    },
    errorMessage: {
      type: String,
      default: "Sorry, there was an error",
    },
    closeMessage: {
      type: String,
      default: "Close",
    },
    tooltipAmountAdded: {
      type: String,
      default: "Monto agregado al crédito",
    },
    detailDialogTitle: {
      type: String,
      default: "Detalle de crédito",
    },
    dataCredit: {
      type: String,
      default: "Datos del crédito",
    },
    statusMessage: {
      type: String,
      default: "Estado",
    },
    addedDateMessage: {
      type: String,
      default: "Fecha de abono",
    },
    expirationDateMessage: {
      type: String,
      default: "Fecha de expiración",
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiClose,
        mdiDownload,
        mdiRefresh,
        mdiDotsVertical,
      },
      loadingTable: false,
    };
  },
  methods: {
    handleClose() {
      if (this.loadingSubmit) {
        return;
      }
      this.$emit("submit");
    },
    getCorporateCredits() {
      this.loadingTable = true;
      axios
        .get(
          `${process.env.VUE_APP_STAGING}/v2/corporate-credits/corporate-list`,
          {
            params: {
              page: this.table.currentPage,
              limit: this.table.limit,
              sortKey: "created_at",
              sortOsc: "-1",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            const { corporateCredits } = response.data.data;
            this.table.items = corporateCredits.docs.map((item) => ({
              ...item,
              expiration_date: item.expiration_date
                ? moment(item.expiration_date).utc().format("DD-MM-YYYY")
                : "",
              credit_add_date: item.credit_add_date
                ? moment(item.credit_add_date)
                    .utc()
                    .format("DD-MM-YYYY")
                : "",
              amount_paid: item.amount_paid ? item.amount_paid : "-",
              amount_added: item.amount_added ? item.amount_added : "-",
              status: item.status,
            }));
            this.table.pages = corporateCredits.pages;
          }
        })
        .catch((error) => {
          console.log("🚩🚩🚩- DM ~ getCorporateCredits ~ error", error);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    async handleExport() {
      const socketId = this.generateSocketId("providersExport");
      axios
        .get(
          `${process.env.VUE_APP_STAGING}/v2/corporate-credits/corporate-export`,
          {
            params: {
              page: this.table.currentPage,
              limit: this.table.limit,
              sortKey: "created_at",
              sortOsc: "-1",
              socketId,
            },
            timeout: 1800000, // 30 minutes in milliseconds
          }
        )
        .then((response) => {
          console.log(
            "🚩🚩🚩- DM ~ ./V2/corporate-credits/corporate-export ~ response:",
            response
          );
        })
        .catch((error) => {
          console.log("🚩🚩🚩- DM ~ handleExport ~ error:", error);
          this.$dialog.notify.error(error?.message || "Ha ocurrido un error");
          throw new Error("Error en request");
        });

      const payload = {
        socketId,
      };

      const dialogParams = {
        fullscreen: !this.$vuetify.breakpoint.lgAndUp,
        width: "40%",
        height: "100%",
        transition: "dialog-top-transition",
        payload: payload,
        scrollable: true,
        showClose: false,
        dialogTitle: this.exportDialogTitle,
        processingMessage: this.processingMessage,
        ofMessage: this.ofMessage,
        downloadMessage: this.downloadMessage,
        goBackMessage: this.goBackMessage,
        expiredMessage: this.expiredMessage,
        youCanDownloadMessage: this.youCanDownloadMessage,
        exportFileHasExpiredMessage: this.exportFileHasExpiredMessage,
        processingPageMessage: this.processingPageMessage,
        errorMessage: this.errorMessage,
        closeMessage: this.closeMessage,
      };

      const dialogPromise = await this.$dialog.showAndWait(
        ExportSocketDialog,
        dialogParams
      );
    },
    handleViewDetail(item) {
      this.$dialog.show(CreditDetailModal, {
        payload: { item },
        persistent: true,
        fullscreen: this.$vuetify.breakpoint.mdAndDown,
        width: "60%",
        height: "100%",
        scrollable: true,
        showClose: true,
        dialogTitle: this.detailDialogTitle,
        dataCredit: this.dataCredit,
        statusMessage: this.statusMessage,
        addedDateMessage: this.addedDateMessage,
        expirationDateMessage: this.expirationDateMessage,
        addedAmountMessage: this.addedAmountMessage,
        paidAmountMessage: this.paidAmountMessage,
        creditMovementsMessage: this.creditMovementsMessage,
      });
    },
    getStatusColor(status) {
      switch (status) {
        case CORPORATE_CREDIT_STATUS_ENUM.CANCEL:
          return "#DE2568";
        case CORPORATE_CREDIT_STATUS_ENUM.OPEN:
          return "#000421";
        case CORPORATE_CREDIT_STATUS_ENUM.ADDED:
          return "#3BB4AE";
        case CORPORATE_CREDIT_STATUS_ENUM.ABOUT_TO_EXPIRE:
          return "#FFA500";
        case CORPORATE_CREDIT_STATUS_ENUM.EXPIRED:
          return "#8B4513";
        case CORPORATE_CREDIT_STATUS_ENUM.PAID_OFF:
          return "#3BD4AE";
        case CORPORATE_CREDIT_STATUS_ENUM.INVOICED:
          return "#800080";
        case CORPORATE_CREDIT_STATUS_ENUM.REVERTED:
          return "#FF2568";
        default:
          return "#000000";
      }
    },
    getStatusText(status) {
      switch (status) {
        case CORPORATE_CREDIT_STATUS_ENUM.CANCEL:
          return "Cancelado";
        case CORPORATE_CREDIT_STATUS_ENUM.OPEN:
          return "Por abonar";
        case CORPORATE_CREDIT_STATUS_ENUM.ADDED:
          return "Abierto";
        case CORPORATE_CREDIT_STATUS_ENUM.ABOUT_TO_EXPIRE:
          return "Por Expirar";
        case CORPORATE_CREDIT_STATUS_ENUM.EXPIRED:
          return "Expirado";
        case CORPORATE_CREDIT_STATUS_ENUM.PAID_OFF:
          return "Pagado";
        case CORPORATE_CREDIT_STATUS_ENUM.INVOICED:
          return "Facturado";
        case CORPORATE_CREDIT_STATUS_ENUM.REVERTED:
          return "Revertido";
        default:
          return "Desconocido";
      }
    },
  },
  created() {
    this.getCorporateCredits();
  },
};
</script>
