<template>
  <v-card tile class="dialog-container">
    <v-container
      fluid
      class="
        d-flex
        flex-column
        align-center
        ma-0
        px-0
        py-4
        justify-space-between justify-center
      "
      style="height: 100%; min-height: 200px"
    >
      <div style="width: 100%" class="px-4">
        <v-row class="ma-0 d-flex" dense>
          <div class="d-flex justify-space- w-full">
            <v-col cols="9">
              <div class="d-flex">
                <h2>{{ dialogTitle }}</h2>
              </div>
            </v-col>
            <v-col cols="3" class="d-flex flex-row justify-end">
              <v-btn @click="handleClose" icon>
                <v-icon color="primary" large>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-col>
          </div>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row class="px-6">
          <v-col md="6" cols="12">
            <v-row>
              <v-col cols="12">
                <h3>
                  {{ dataCredit }}
                </h3>
              </v-col>
            </v-row>

            <v-row class="ma-0 mt-2 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                {{ statusMessage }}
                <v-chip
                  :color="getStatusColor(payload.item.status)"
                  text-color="white"
                  small
                >
                  {{ getStatusText(payload.item.status) }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                {{ addedDateMessage }}
                <strong>
                  {{ payload.item.credit_add_date }}
                </strong>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                {{ expirationDateMessage }}
                <strong>
                  {{ payload.item.expiration_date }}
                </strong>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="6" cols="12">
            <v-row>
              <v-col cols="12">
                <h3>
                  {{ creditMovementsMessage }}
                </h3>
              </v-col>
            </v-row>

            <v-row class="ma-0 mt-2 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                {{ amountAddedMessage }}
                <strong>
                  {{ payload.item.amount_added | currency }}
                </strong>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                {{ paidAmountMessage }}
                <strong>
                  {{ payload.item.amount_paid | currency }}
                </strong>
              </v-col>
            </v-row>
          </v-col>

          <v-row class="my-4">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :disable-sort="true"
                class="dataTable"
                hide-default-footer
                disable-pagination
                fixed-header
                :dense="false"
                calculate-widths
              >
                <template v-slot:no-data>
                  ¡No existen movimientos de pago del crédito!
                </template>

                <template #item.amount="{ value }">
                  {{ value | currency }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import moment from "moment";
import BaseDialog from "@/components/BaseDialog.vue";
import { mdiClose } from "@mdi/js";

const CORPORATE_CREDIT_STATUS_ENUM = {
  CANCEL: -1,
  OPEN: 1,
  ADDED: 2,
  ABOUT_TO_EXPIRE: 3,
  EXPIRED: 4,
  PAID_OFF: 5,
  INVOICED: 6,
  REVERTED: 7,
};

export default {
  name: "CreditDetailModal",
  components: {
    BaseDialog,
  },
  props: {
    dialogTitle: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    dataCredit: {
      type: String,
      default: "Datos del adelanto",
    },
    statusMessage: {
      type: String,
      default: "Estatus",
    },
    addedDateMessage: {
      type: String,
      default: "Fecha de aprobación",
    },
    expirationDateMessage: {
      type: String,
      default: "Fecha de vencimiento",
    },
    amountAddedMessage: {
      type: String,
      default: "Monto adelanto",
    },
    paidAmountMessage: {
      type: String,
      default: "Monto pagado",
    },
    creditMovementsMessage: {
      type: String,
      default: "Movimientos del crédito",
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      headers: [
        { text: "ID", value: "unique_id", align: "center" },
        { text: "Fecha", value: "created_at", align: "center" },
        { text: "Monto", value: "amount", align: "center" },
        { text: "Origen de la transacción", value: "add_credit_comment", align: "center" },
      ],
      items: [],
      loading: false,
    };
  },
  methods: {
    handleClose() {
      this.$emit("submit");
    },
    async getCorporateCreditHistory() {
      this.loading = true;
      const params = {
        limit: 100,
        corporateCreditId: this.payload.item.id,
      };
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate-credit-histories/corporate/`,
          {
            params,
          }
        );
        this.items = data?.data?.corporateCreditHistories?.docs.map((item) => ({
          ...item,
          created_at: moment(item.created_at).format("DD-MM-YYYY"),
        }));
      } catch (error) {
        console.error("Error al cargar las solicitudes de crédito", error);
        this.$dialog.notify.error("Error al cargar las solicitudes de crédito");
      } finally {
        this.loading = false;
      }
    },
    getStatusColor(status) {
      switch (status) {
        case CORPORATE_CREDIT_STATUS_ENUM.CANCEL:
          return "#DE2568";
        case CORPORATE_CREDIT_STATUS_ENUM.OPEN:
          return "#000421";
        case CORPORATE_CREDIT_STATUS_ENUM.ADDED:
          return "#3BD4AE";
        case CORPORATE_CREDIT_STATUS_ENUM.ABOUT_TO_EXPIRE:
          return "#FFA500";
        case CORPORATE_CREDIT_STATUS_ENUM.EXPIRED:
          return "#8B4513";
        case CORPORATE_CREDIT_STATUS_ENUM.PAID_OFF:
          return "#3BD4AE";
        case CORPORATE_CREDIT_STATUS_ENUM.INVOICED:
          return "#800080";
        case CORPORATE_CREDIT_STATUS_ENUM.REVERTED:
          return "#FF2568";
        default:
          return "#000000";
      }
    },
    getStatusText(status) {
      switch (status) {
        case CORPORATE_CREDIT_STATUS_ENUM.CANCEL:
          return "Cancelado";
        case CORPORATE_CREDIT_STATUS_ENUM.OPEN:
          return "Por abonar";
        case CORPORATE_CREDIT_STATUS_ENUM.ADDED:
          return "Abierto";
        case CORPORATE_CREDIT_STATUS_ENUM.ABOUT_TO_EXPIRE:
          return "Por Expirar";
        case CORPORATE_CREDIT_STATUS_ENUM.EXPIRED:
          return "Expirado";
        case CORPORATE_CREDIT_STATUS_ENUM.PAID_OFF:
          return "Pagado";
        case CORPORATE_CREDIT_STATUS_ENUM.INVOICED:
          return "Facturado";
        case CORPORATE_CREDIT_STATUS_ENUM.REVERTED:
          return "Revertido";
        default:
          return "Desconocido";
      }
    },
  },
  created() {
    this.getCorporateCreditHistory();
  },
};
</script>
