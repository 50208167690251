var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column justify-between"},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],staticClass:"pa-2",attrs:{"label":_vm.$t('Amount to add'),"prefix":"$","outlined":"","dense":"","validate-on-blur":"","rules":[
      (v) => !!v || _vm.$t('Amount is required'),
      (v) => {
        const numericValue =
          typeof v === 'number'
            ? v
            : parseFloat(this.data.amount.replace(/,/g, ''));
        return numericValue > 0 || _vm.$t('Amount must be greater than 0');
      },
    ]},model:{value:(_vm.data.amount),callback:function ($$v) {_vm.$set(_vm.data, "amount", $$v)},expression:"data.amount"}}),_c('div',{staticClass:"button-group"},[_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","outlined":""},on:{"click":function($event){return _vm.$emit('closePaymentDrawer')}}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]),_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","color":"primary","disabled":_vm.handleDisabled},on:{"click":_vm.handleNext}},[_vm._v(" "+_vm._s(_vm.$t("Continue"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }